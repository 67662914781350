import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14L1 12ZM1 14L32 14V12L1 12L1 14Z" fill="#BF348B" />
        <path d="M20 1L32 13L20 25" stroke="#BF348B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Icon;
